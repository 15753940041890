<template>
  <LayoutBase>
    <DefaultAppHeader v-if="menu" />

    <v-layout class="overflow-inherit!">
      <v-main :class="{ 'pt-30! pb-12!': !noPad && menu }">
        <slot />
      </v-main>
    </v-layout>

    <DefaultAppFooter v-if="menu" />
    <CreditAppFooter v-else />

    <div v-if="menu && !noMobileNav" class="md:hidden">
      <MobileNavFooter />
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { get } from "lodash";
import LayoutBase from "@/components/layout/LayoutBase.vue";
import {
  DefaultAppHeader,
  DefaultAppFooter,
  CreditAppFooter,
} from "@/components/layout";
import MobileNavFooter from "@/components/layout/footers/MobileNavFooter.vue";

export default defineComponent({
  components: {
    LayoutBase,
    DefaultAppHeader,
    DefaultAppFooter,
    CreditAppFooter,
    MobileNavFooter,
  },
  setup() {
    const route = useRoute();

    const menu = computed(() => {
      return route.query.menu !== "false";
    });
    const noMobileNav = computed(() => {
      return get(route.meta, "noMobileNav", false);
    });
    const noPad = computed(() => route.meta.noAppHeaderPadding);

    return {
      menu,
      noMobileNav,
      noPad,
    };
  },
});
</script>
